.product-type-card-container {
    display: flex;
    justify-content: center;
}
.product-type-image {
    padding: 0.5rem;
    object-fit: contain;
    margin: 0 auto;
    border-bottom: rgb(245, 245, 245) 1px solid;
}
.product-type-card {
    max-width: 200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.content-card-container {
    padding: 24px;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
}
.content-card-container h1 {
    text-align: left;
    padding-bottom: 1rem;
}
.content-card-container p {
    text-align: center;
}
.ant-card-meta-title {
    font-size: 20px !important;
    text-align: center;
}
.ant-menu {
    position: sticky;
    top: 0px;
    left: 0;
    height: 100vh;
    overflow-y: auto;
}
.ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
}
.submit-btn {
    width: 250px;
    margin: 0 auto;
    margin-top: 1rem;
}
.product-card-parent {
    border-block-end: 1px solid rgba(5, 5, 5, 0.06) !important;
    height: 100px;
}
.product-card-parent:hover {
    background: #f5f5f5;
}
.product-card {
    padding: 24px !important;
}
.content-card-body {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    font-size: 16px;
}
.title-search-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content-container {
    margin: 24px 16px 0px;
}
.product-details-form-item {
    margin-right: 8px;
    margin-top: 24px;
    width: 100%;
}
.product-details-form {
    display: flex;
    margin-bottom: 24px;
    padding: 0 20px;
    width: 100%;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 10px;
}

/* MOBILE */
@media (max-width: 1000px) {
    .product-details-form-subitem {
        margin: 0 !important;
    }
    .product-details-form-item {
        margin: 10px 0 !important;
    }
    .product-details-form {
        flex-direction: column;
        padding: 10px 20px;
    }
}
@media (max-width: 576px) {
    .content-card-image {
        display: flex;
        justify-content: center;
    }
    .product-details-list {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .content-container {
        margin: 0;
    }
    .product-type-card {
        max-width: 180px;
    }
    .product-type-image {
        width: 180px !important;
        height: 180px !important;
    }
    .content-card-container {
        padding: 10px;
    }
    .content-card-container h1 {
        text-align: center;
    }
    .title-search-wrapper {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
    }
    .submit-btn {
        width: 100%;
    }
}
.ant-avatar-lg img {
    object-fit: contain !important;
}